class SideMenu {
  el;
  buttonOpen;
  buttonClose;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector(".js-button-side-menu");
    this.buttonClose = this.el.querySelector(".js-button-close-side-menu");

    if (this.buttonOpen) {
      this.buttonOpen.addEventListener("click", this.open.bind(this));
    }
    if (this.buttonClose) {
      this.buttonClose.addEventListener("click", this.close.bind(this));
    }
  }

  open() {
    this.el.classList.add("open");
    this.buttonOpen.setAttribute("aria-expanded", true);
    this.buttonClose.setAttribute("aria-expanded", true);
    this.buttonClose.style.animation =
      "buttonRotate .75s cubic-bezier(0.25, 0.1, 0, 0.77) normal";
  }

  close() {
    this.buttonOpen.setAttribute("aria-expanded", false);
    /* this.buttonClose.style.animation =
      "buttonRotateClose 0.5s cubic-bezier(0.25, 0.1, 0, 0.77) .25s"; */
    this.buttonClose.setAttribute("aria-expanded", false);
    this.el.classList.remove("open");

    /* setTimeout(() => {
      this.buttonClose.style.animation = "";
    }, 500); */
  }
}

const sideMenu = document.querySelector(".js-side-menu");
if (sideMenu) {
  new SideMenu(sideMenu);
}
